<template>
  <div class="betting-offer-event">
   <EventId class="event-id-wrapper"
            :event-id="event.lotteryDisplayId" />
    <section class="flag-category-tournament-time">
      <div class="flag-wrapper">
        <img :src="require(`../../assets/images/flags/${event.category.name}.png`)"
             :alt="event.category.isoCode"
             :style="getFlagSize">
        <span>{{ event.category.shortName }}</span>
      </div>
      <p>{{replaceName}}</p>
      <p>{{getTime}}</p>
    </section>
    <Market :market="getMarket(event.markets, '832')"
            :secondary-name="translate('draw')"
            :betting-offer="true"
            :sidebar="true"/>
  </div>
</template>

<script>
import EventId from '@/components/Retail/EventId';
import { mapGetters } from 'vuex';
import { formatDate, searchMarket } from '@/utility';
import Market from '@/components/Retail/Market';

export default {
  name: 'BettingOfferEvent',
  components: { Market, EventId },
  data() {
    return {
      sizes: {
        1280: {
          flag: {
            width: '30px',
            height: '20px',
          },
        },
        1920: {
          flag: {
            width: '45px',
            height: '30px',
          },
        },
      },
    };
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'screenSize',
      'translate',
      'timeZone',
    ]),
    getFlagSize() {
      const size = this.sizes[this.screenSize] || {
        flag: {
          width: '30px',
          height: '20px',
        },
      };
      return size.flag;
    },
    getTime() {
      return formatDate(this.event.eventStartTimeUTC, 'HH:mm', this.timeZone);
    },
    replaceName() {
      return this.event.tournament.shortName
        .replace(this.event.category.shortName, '')
        .replace(this.event.category.shortName, '');
    },
  },
  methods: {
    getMarket(markets, betType) {
      return searchMarket(markets, betType);
    },
  },
};
</script>

<style lang="scss" scoped>
.betting-offer-event {
  .event-id-wrapper {
    margin-top: 14px;
    @media (min-width: 1920px) {
      margin-top: 17px;
    }
  }
  .flag-category-tournament-time {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 7px;
    position: relative;
    @media (min-width: 1920px) {
      margin-top: 10px;
    }
    .flag-wrapper {
      align-items: center;
      display: flex;
      img {
        display: block;
      }
      span {
        color: #F3F3F3;
        font-size: 16px;
        font-weight: 700;
        padding-left: 8px;
        @media (min-width: 1920px) {
          font-size: 24px;
        }
      }
    }
    > p {
      font-size: 16px;
      @media (min-width: 1920px) {
        font-size: 24px;
      }
      &:nth-child(2) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      &:last-child {
        color: #F3F3F3;
        font-weight: 700;
      }
    }
  }
}
</style>
