<template>
<div class="betting-offer">
  <SidebarTitle :title="$store.getters.translate('bettingOffer')"
                :subtitle="$store.getters.translate('notDisplayed')"/>
  <section class="betting-offer-events"
           ref="bettingOfferEvents">
    <div class="betting-offer-events-wrapper"
         :class="{'active': activeContent}"
         ref="bettingOfferEventsWrapper">
      <BettingOfferEvent v-for="event in bettingOffer(part)"
                         :key="event.lotteryDisplayId"
                         :event="event" />
    </div>
  </section>
</div>
</template>

<script>
import SidebarTitle from '@/components/Retail/SidebarTitle';
import { mapGetters } from 'vuex';
import BettingOfferEvent from '@/components/Retail/BettingOfferEvent';
import switchComponent from '@/utility/switchComponent';
import EventBus from '@/store/EventBus';

export default {
  name: 'BettingOffer',
  components: { BettingOfferEvent, SidebarTitle },
  data() {
    return {
      part: 0,
      interval: null,
      activeContent: true,
      changContentTimeout: null,
    };
  },
  computed: {
    ...mapGetters([
      'bettingOffer',
      'intervalTimeForBettingEvents',
      'isVisualizationDrawEnabled',
    ]),
  },
  methods: {
    changeContent() {
      if (this.part >= 3 && this.isVisualizationDrawEnabled) {
        clearInterval(this.interval);
        return;
      }
      if (!this.bettingOffer(this.part)) {
        this.closeComponent(0);
      }
      this.activeContent = false;
      this.changContentTimeout = setTimeout(() => {
        if (!this.isVisualizationDrawEnabled && this.part === 3) {
          this.part = -1;
        }
        this.part += 1;
        this.activeContent = true;
      }, 500);
    },
    closeComponent(duration = 40000) {
      switchComponent(
        'right',
        1,
        duration,
      );
    },
    clearInterval() {
      clearInterval(this.interval);
      clearTimeout(this.changContentTimeout);
    },
  },
  mounted() {
    EventBus.$on('stopIntervalsAndTimeouts', () => {
      this.clearInterval();
    });
    this.interval = setInterval(() => {
      this.changeContent();
    }, this.intervalTimeForBettingEvents);
    this.closeComponent();
  },
  beforeDestroy() {
    this.clearInterval();
  },
};
</script>

<style lang="scss" scoped>
.betting-offer {
  height: 100%;
  .betting-offer-events {
    height: calc(100% - 47px);
    overflow: auto;
    @media (min-width: 1920px) {
      height: calc(100% - 71px);
    }
    .betting-offer-events-wrapper {
      opacity: 0;
      &.active {
        opacity: 1;
      }
      transition: all .5s linear;
    }
  }
}
</style>
